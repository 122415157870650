<template>
  <div class="container">
    <h1 id="headline">SKILLS</h1>
    <v-carousel continuous touch height="600" hide-delimiters>
      <v-carousel-item>
        <div class="skillContainer">
          <span class="skillContainerTitle">Webdesign</span>
          <v-item-group class="skillGroup">
            <v-avatar dark size="60" tile class="borderedAvatar">
              <v-icon color="orange darken-4" size="30"> mdi-vuejs</v-icon>
            </v-avatar>
            <h1><span class="skillTitle">Vue.js</span></h1>
            <p class="skillDescription">
              Building great looking Websites with
              <span class="skillHighlight">Vue.js</span>
            </p>
          </v-item-group>

          <v-item-group class="skillGroup">
            <v-avatar dark size="60" tile class="borderedAvatar">
              <v-icon color="orange darken-4" size="30">mdi-responsive</v-icon>
            </v-avatar>
            <h1><span class="skillTitle">Responsive</span></h1>
            <p class="skillDescription">
              Your website will always be great looking, no matter the
              <span class="skillHighlight">size</span>
            </p>
          </v-item-group>

          <v-item-group class="skillGroup">
            <v-avatar dark size="60" tile class="borderedAvatar">
              <v-icon color="orange darken-4" size="30">mdi-lightbulb</v-icon>
            </v-avatar>
            <h1><span class="skillTitle">Simple</span></h1>
            <p class="skillDescription">
              Easy to use &amp; intuitive
              <span class="skillHighlight">UI/UX</span>
            </p>
          </v-item-group>
        </div>
      </v-carousel-item>
      <v-carousel-item>
        <div class="skillContainer">
          <span class="skillContainerTitle">Software</span>
          <v-item-group class="skillGroup">
            <v-avatar dark size="60" tile class="borderedAvatar">
              <v-icon color="orange darken-4" size="30">mdi-code-braces</v-icon>
            </v-avatar>
            <h1><span class="skillTitle">C / C++</span></h1>
            <p class="skillDescription">
              Front- / Backend Developement with<br />
              <span class="skillHighlight">C, Qt &amp; C++</span>
            </p>
          </v-item-group>

          <v-item-group class="skillGroup">
            <v-avatar dark size="60" tile class="borderedAvatar">
              <v-icon color="orange darken-4" size="30"
                >mdi-monitor-shimmer</v-icon
              >
            </v-avatar>
            <h1><span class="skillTitle">Clean Code</span></h1>
            <p class="skillDescription">Develop the best</p>
          </v-item-group>

          <v-item-group class="skillGroup">
            <v-avatar dark size="60" tile class="borderedAvatar">
              <v-icon color="orange darken-4" size="30">mdi-sync</v-icon>
            </v-avatar>
            <h1><span class="skillTitle">Agile</span></h1>
            <p class="skillDescription">
              Working with <span class="skillHighlight">SCRUM</span>
            </p>
          </v-item-group>
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "SkillPage",
};
</script>

<style scoped>
.borderedAvatar {
  border: 3px solid #e56100;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

#headline {
  width: 100%;
  color: #e56100;
  text-align: center;
  font-size: 24pt;
  margin-top: 15%;
}

.skillContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 1%;
  border: 3px solid #e56100;
  margin-bottom: 5%;
}

.skillContainerTitle {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  color: #fff;
  font-size: 24pt;
  text-align: center;
  margin-bottom: 1%;
}

.skillGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80%;
  color: #ccc;
}

.skillContainer.noMarginTop {
  margin-top: 0;
}

.skillTitle {
  color: #fff;
  font-size: 12pt;
}

.skillDescription {
  font-size: 10pt;
}

.skillHighlight {
  color: #e56100;
  font-weight: "bold";
}

@media (min-width: 900px) {
  .skillContainer {
    margin-top: 5%;
    flex-direction: row;
  }

  .skillGroup {
    width: 25%;
  }

  .skillTitle {
    font-size: 20pt;
  }

  .skillDescription {
    font-size: 14pt;
  }
}
</style>

<template>
  <div id="contactContainer">
    <canvas id="triangleCanvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "ContactPage",
  data() {
    return {
      ctx: null,
      canvas: null,
      xMax: 0,
      yMax: 0,
    };
  },

  mounted: function () {
    this.canvas = document.getElementById("triangleCanvas");
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight / 10;
    this.ctx = this.canvas.getContext("2d");
    this.xMax = window.innerWidth;
    this.yMax = window.innerHeight / 10;
    this.init();
  },

  methods: {
    init() {
      console.log(this.xMax, this.yMax);
      let ctx = this.ctx;
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(this.xMax, 0);
      ctx.lineTo(this.xMax / 2, this.yMax);
      ctx.fillStyle = "#121212";
      ctx.strokeStyle = "#121212";
      ctx.stroke();
      ctx.fill();
    },
  },
};
</script>

<style scoped>
#contactContainer {
  width: 100%;
  height: 100vh;
  background-color: #e56100;
}

#triangleCanvas {
}
</style>

<template>
  <div class="container">
    <div id="marginTopDiv">
      <h1 id="headline">ABOUT</h1>
      <div id="availableDate">Available from: 01.12.2021</div>
      <div id="roundProfilePic">
        <img src="@/assets/profile_pic.jpg" />
      </div>
      <div id="profileDescription">
        I'm a freelancer developing Software &amp; Websites from Wuppertal,
        Germany. My passion is creating the look and feel you have on your mind.
      </div>

      <v-btn @click="gotoSkills" id="showSkillButton" large>
        Show my skills!
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomePage",

  data() {
    return {
      fab: false,
    };
  },

  methods: {
    gotoSkills() {
      this.$root.$emit("goto", "skillPage");
    },
  },
};
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
}

#marginTopDiv {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10%;
  width: 100%;
}

#headline {
  width: 100%;
  color: #e56100;
  text-align: center;
  font-size: 24pt;
}

#availableDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 5vh;
  background-color: #e56100;
  font-weight: bold;
  font-size: 14pt;
  color: black;
}

#roundProfilePic {
  margin-top: 5%;
  width: 25vh;
  height: 25vh;
  border-radius: 5%;

  object-fit: cover;
  overflow: hidden;
}

img {
  width: 100%;
}

#profileDescription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 2%;
  font-size: 12pt;
  color: #ccc;
  font-weight: bold;
  text-align: center;
  padding: 5% 5%;
}

#showSkillButton {
  margin-top: 2%;
  background-color: transparent;
  border: 2px solid #fff;
  transition: 0.6s ease all;
  overflow: hidden;
}

#showSkillButton:hover {
  background-color: #e56100;
  border-color: #e56100;
}
</style>

<template>
  <div>
    <section>
      <app-navigation id="appNavigation"></app-navigation>
      <landing-page></landing-page>
    </section>
    <section class="spacer"></section>
    <section><welcome-page id="welcomePage"></welcome-page></section>
    <section class="spacer"></section>
    <section><skill-page id="skillPage"></skill-page></section>
    <section class="spacer"></section>
    <section><contact-page></contact-page></section>
  </div>
</template>

<script>
import AppNavigation from "../components/AppNavigation.vue";
import LandingPage from "../components/LandingPage.vue";
import WelcomePage from "../components/WelcomePage.vue";
import SkillPage from "../components/SkillPage.vue";
import ContactPage from "../components/ContactPage.vue";

export default {
  name: "Home",
  components: {
    LandingPage,
    AppNavigation,
    WelcomePage,
    SkillPage,
    ContactPage,
  },

  mounted() {
    this.$root.$on("goto", (ref) => {
      var elem = document.getElementById(ref);
      var top = elem.offsetTop;
      this.customScroll(0, top, 500);
    });
  },

  methods: {
    async customScroll(x, y, duration) {
      var yStart = window.scrollY;
      var stepSize = 20;
      var steps = (y - window.scrollY) / stepSize;
      var time = duration / steps;

      for (var yPos = yStart; yPos < y; yPos += stepSize) {
        window.scrollTo(0, yPos);
        await this.scrollDelay(time);
      }
    },

    scrollDelay(ms) {
      return new Promise((res) => setTimeout(res, ms));
    },
  },
};
</script>

<style scoped>
section {
  height: 100%;
  width: 100vw;
  background-color: #121212;
}

.spacer {
  width: 100vw;
  height: 10vh;
}
</style>

<template>
  <span>
    <v-navigation-drawer
      app
      v-model="drawer"
      color="grey darken-4"
      dark
      disable-resize-watcher
      width="50%"
    >
      <v-list>
        <v-list-item-group>
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-icon>
              <v-icon v-text="item.icon" color="orange darken-4"></v-icon>
            </v-list-item-icon>
            <v-list-item-content> {{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      id="appBar"
      app
      elevate-on-scroll
      inverted-scroll
      v-bind:scroll-threshold="scrollHeight"
    >
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
        color="orange darken-4"
      ></v-app-bar-nav-icon>

      <v-tabs class="hidden-sm-and-down" color="orange darken-4">
        <v-tab v-for="(item, i) in items" :key="i" dark>{{ item.title }}</v-tab>
      </v-tabs>
    </v-app-bar>
  </span>
</template>

<script>
export default {
  name: "AppNavigation",
  data() {
    return {
      appTitle: "Yannic Bartel",
      drawer: false,
      scrollHeight: 0,
      items: [
        {
          title: "Home",
          icon: "mdi-account-circle",
        },
        {
          title: "Skills",
          icon: "mdi-head-snowflake",
        },
        { title: "Experience", icon: "mdi-briefcase" },
      ],
    };
  },
};
</script>

<style scoped>
#appBar {
  background-color: #111;
}
.customTextColor {
  color: #e65100;
  font-family: monospace;
}
</style>

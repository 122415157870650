<template>
  <div id="container">
    <canvas id="canvas" @resize="init"></canvas>
    <div id="enterModal">
      <div class="text">
        Hello World! <br /><span class="coloredText">I'm Yannic Bartel.</span>
        I develop Software &amp; Websites
      </div>
      <v-btn @click="enterPage" id="enterButton" large width="200px">
        Enter
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  props: ["pageEntered"],
  data() {
    return {
      ctx: null,
      canvas: null,
      numOfLines: 20,
      lines: [],
      started: false,
      playing: false,
      scrollStep: 0,
    };
  },
  mounted: function () {
    this.canvas = document.getElementById("canvas");
    this.ctx = this.canvas.getContext("2d");

    this.canvas.height = window.innerHeight;
    this.canvas.width = window.innerWidth;
    var elem = this;

    window.addEventListener("resize", function () {
      this.canvas.height = window.innerHeight;
      this.canvas.width = window.innerWidth;
      elem.init();
    });

    this.init();
  },

  destroyed() {
    window.removeEventListener("resize");
  },

  methods: {
    init() {
      this.lines = [];

      for (let i = 0; i < this.numOfLines; i++) {
        var line = {
          number: i,
          lineElements: [],
          speed: this.getRandomInt(3) + 2,
          verticalSpeed: this.canvas.width > this.canvas.height ? 0.01 : 0.05,
        };

        var space = 20;
        var useableWidth = this.canvas.width;
        while (useableWidth > 0) {
          var lineElement = {
            x: this.canvas.width - useableWidth + space,
            width: this.getRandomInt(100),
            opacity: 1,
            color: this.getRandomInt(4),
            filled: this.getRandomInt(2),
          };

          line.lineElements.push(lineElement);

          if (useableWidth - lineElement.width < 0) {
            lineElement.width = useableWidth;
          }
          useableWidth -= lineElement.width + space;
        }
        this.lines.push(line);
      }
      if (!this.started) {
        this.started = true;
        this.playing = true;
        window.requestAnimationFrame(this.draw);
      }
    },

    draw() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.lines.forEach((line) => {
        line.lineElements.forEach((lineElement) => {
          this.drawLine(lineElement, line.number);
          this.updateLineElement(lineElement, line.speed);
        });
      });
      window.requestAnimationFrame(this.draw);
    },

    drawLine(lineElement, lineNumber) {
      var lineHeight = this.canvas.height / 20 / 4;

      if (lineElement.color == 1) {
        this.ctx.strokeStyle = "rgba(90,38,0," + lineElement.opacity + ")";
        this.ctx.fillStyle = "rgba(90,38,0," + lineElement.opacity + ")";
      } else if (lineElement.color == 2) {
        this.ctx.strokeStyle = "rgba(150,150,150," + lineElement.opacity + ")";
        this.ctx.fillStyle = "rgba(150,150,150," + lineElement.opacity + ")";
      }

      this.ctx.lineWidth = 3;

      if (lineElement.filled == 1) {
        this.ctx.fillRect(
          lineElement.x,
          lineNumber * lineHeight * 4,
          lineElement.width,
          lineHeight
        );
      } else {
        this.ctx.strokeRect(
          lineElement.x,
          lineNumber * lineHeight * 4,
          lineElement.width,
          lineHeight
        );
      }
    },

    updateLineElement(lineElement, speed) {
      lineElement.x += speed;

      if (lineElement.x < this.canvas.width / 2) {
        lineElement.opacity = lineElement.x / this.canvas.width;
      } else {
        lineElement.opacity = 1 - lineElement.x / this.canvas.width;
      }
      if (lineElement.x > this.canvas.width) {
        lineElement.x = 0;
        lineElement.opacity = 0;
      }
    },

    updateLine(line) {
      line.number -= line.verticalSpeed;
    },

    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },

    enterPage() {
      this.$root.$emit("goto", "welcomePage");
    },
  },
};
</script>

<style scoped>
html,
body {
  margin: 0 !important;
  padding: 0 !important;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  font-size: 20pt;
  text-align: center;
}
.coloredText {
  color: #e56100;
}

#container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

#enterModal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16pt;
}

#enterButton {
  margin-top: 2%;
  background-color: transparent;
  border: 2px solid #fff;
  transition: 0.3s ease all;
}

#enterButton:hover {
  background-color: #e56100;
  border: 2px solid #e56100;
}

@media (min-width: 900px) {
  .text {
    font-size: 36pt;
  }
  #enterModal {
    font-size: 36pt;
  }
}

#canvas {
  width: 100%;
  height: 100%;
  background-color: #121212;
}
</style>
